import React from "react";
import Pagelayout from "../../layouts/Pagelayout";
import Missions from "./missions/Missions";

function Landingmissions() {
  return (
    <Pagelayout
      title="Discouvrez nos missions"
      description="Nous avons un champs d'impacte diversifié"
    >
      <Missions />
    </Pagelayout>
  );
}

export default Landingmissions;
