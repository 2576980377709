import React, { useState } from "react";
import Pagelayout from "../../layouts/Pagelayout";
import Contact from "./contact/Contact";

function Landingcontact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      subject,
      message,
    };

    alert(JSON.stringify(data));
  };
  return (
    <Pagelayout title="Get in Touch" description="We are available to help you">
      <Contact />
    </Pagelayout>
  );
}

export default Landingcontact;
