import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Title from "../../../components/Title";
import appConfig from "../../../constants/configurations";
import IMAGES from "../../../constants/images";
import axiosInstance from "../../../utils/axiosInstance";

// news/list
function Actualite() {
  const { isLoading, data, refetch, isSuccess } = useQuery(
    "newsData",
    async () => {
      const { data } = await axiosInstance.get(`${appConfig.API_URL}/news/list`);
      return data.data;
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div
      className="container_fluid pb-20"
      data-aos="fade-up"
      data-aos-delay={500}
    >
      <Title title="Nos Activités recentes" />
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-between"> */}
      <Swiper
        modules={[Pagination, Navigation]}
        spaceBetween={15}
        slidesPerView={3}
        navigation={true}
        pagination={true}
        cssMode={true}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 980px
          980: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {isSuccess && data.slice(0, 8).map((item, i) => {
          return (
            <SwiperSlide key={i}>
              <Link
                to={`/actualite/${item.id}`}
                className="shadow-md rounded-lg overflow-hidden mb-12 cursor-pointer"
              >
                <div className="relative h-56 overflow-hidden">
                  <img
                    src={`${appConfig.API_HOST}uploads/${item?.images.split("|")[0]}` || IMAGES.FORMATION}
                    alt="Actualités"
                    className=" object-scale-down animation duration-300 hover:scale-110"
                  />
                  <span className="absolute top-2 right-2 bg-primary-green/90 text-white p-2 px-3 text-sm rounded-full">
                  {item?.start_date}
                  </span>
                </div>
                <div className="p-2 flex flex-col gap-2 mb-2 px-3">
                  <div className="flex flex-col">
                    <span className="text-base font-semibold">
                      {item?.title}
                    </span>
                    <span className="text-sm">{item?.end_date}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm">10 vue(s)</span>
                    <Link
                      to={`/actualite/${item.id}`}
                      className="text-sm text-primary-green hover:underline"
                    >
                      Consulter
                    </Link>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* </div> */}
    </div>
  );
}

export default Actualite;
