import React, { useState } from "react";
import { BiAlarm, BiMapPin, BiMessageAlt, BiPhoneCall } from "react-icons/bi";
import { useMutation, useQueryClient } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Title from "../../../components/Title";
import axiosInstance from "../../../utils/axiosInstance";
import ContactCard from "./../../../components/Cards/ContactCard";
import FormInput from "./../../../components/FormElements/FormInput";
import appConfig from "./../../../constants/configurations";
import styles from "./contact.module.css";

const missions = [
  {
    id: 0,
    title:
      "l'accompagnement des futurs entrepreneurs dans les études de marché",
  },
  {
    id: 1,
    title:
      "Collecte et analyse des données agropastorales et socio-économiques et démographiques",
  },
  {
    id: 2,
    title: "Conception et réalisation d’enquêtes agricoles",
  },
  {
    id: 3,
    title:
      "Conception et réalisation d’enquêtes socio-économique pour des études de planification urbaine, de marchés etc",
  },
  {
    id: 4,
    title: "Conception et réalisation d’autres enquêtes",
  },
  {
    id: 5,
    title: "Montage des projets issus des problèmes de population",
  },
  {
    id: 6,
    title:
      "Valorisation des données existantes (Données de CountrySTAT, ONU-Habitat, INS, UNESCO, HCR, etc)",
  },
  {
    id: 7,
    title:
      "Formation des acteurs sociaux en analyse des données et utilisation des outils statistiques",
  },
  {
    id: 8,
    title:
      "Appui technique auprès des Cabinets agropastorales, d’urbanistes et des ONGs œuvrant dans le domaine de l’agropastorale et de l’Humanitaire",
  },
  {
    id: 9,
    title: "Gestion et analyse des données ",
  },
  {
    id: 10,
    title: "Offre de service data online",
  },
  {
    id: 11,
    title: "Data center",
  },
];

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient()

  const sendMail = async ()=>{
    const payload = {
      name,
      email,
      subject,
      service,
      message,
    };
    const { data } = await axiosInstance.post(`${appConfig.API_URL}/contacts`, payload);
    return data
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate();
  };

  const { mutate, isLoading } = useMutation(sendMail, {
    onSuccess: (data) =>{
      toast.success(data.message);
      setName("");
        setEmail("");
        setService("");
        setSubject("");
        setMessage("");
      },
      onError: (error)=>{
      toast.error(error.data.message);
    },
    onSettled: ()=>{
      queryClient.invalidateQueries('create')
    }
  }
  )
  // if (isLoading){
  //   toast.loading("Envoie en cours",{
  //     isLoading: true,
  //     toastId: 'info1',
  //     autoClose: 1000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: true,
  //   })
  // }
 

  return (
    <div className={`container_fluid py-24 ${styles.contact}`}>
      <ToastContainer />
      <Title title="LAISSER NOUS UN MESSAGE" />
      <div className="w-full flex flex-col md:flex-row gap-9">
        <div className="w-full md:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-4">
          <ContactCard
            icon={<BiMapPin size={35} className="text-primary-light" />}
            label="Localisation"
          >
            <div className="flex flex-col">
              <span>Cameroun</span>
              <span>BP: 145, Bertoua</span>
            </div>
          </ContactCard>

          <ContactCard
            icon={<BiMessageAlt size={35} className="text-primary-light" />}
            label="Email"
          >
            <div className="flex flex-col">
              <span>scsm@scsmaubmar.org</span>
              <span>support@scsmaubmar.org</span>
            </div>
          </ContactCard>

          <ContactCard
            icon={<BiPhoneCall size={35} className="text-primary-light" />}
            label="Telephone"
          >
            <div className="flex flex-col">
              <span>+(237) 620 307 439</span>
              <span>+(237) 674 651 856</span>
              <span>+(237) 663 433 487</span>
            </div>
          </ContactCard>

          <ContactCard
            icon={<BiAlarm size={35} className="text-primary-light" />}
            label="Disponibilité"
          >
            <div className="flex flex-col">
              <span>Lundi - Samedi</span>
              <span>8:00 - 17:00</span>
            </div>
          </ContactCard>
        </div>
        <div className="w-full md:w-1/2 relative">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 relative"
          >
            <div className="flex flex-col md:flex-row gap-4">
              <FormInput
                type="text"
                placeholder="Votre Nom"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                required={true}
              />
              <FormInput
                type="email"
                placeholder="Votre Email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
            </div>

            <div>
              <FormInput
                type="text"
                placeholder="Objet"
                value={subject}
                name="subject"
                onChange={(e) => setSubject(e.target.value)}
                required={true}
              />
            </div>

            <div>
              <select
                name="service"
                id="service"
                required
                value={service}
                defaultValue={service}
                onChange={(e) => setService(e.target.value)}
                className="w-full py-3 rounded-md px-2 text-sm outline-none focus:border-green-700 text-white bg-transparent border-[1px] border-white"
              >
                <option className="bg-green-600" selected value="">
                  Selectionner
                </option>
                {missions &&
                  missions.map((item) => {
                    return (
                      <option
                        className="bg-green-700"
                        key={item.id}
                        value={item.title}
                      >
                        {item.title.substring(60, 0)}
                      </option>
                    );
                  })}
              </select>
            </div>

            <textarea
              name="message"
              placeholder="Ecrivez Votre Message Ici ..."
              id=""
              value={message}
              required="true"
              className="w-full animation ease-in-out duration-300 outline-none border-[1px] rounded-md p-3 font-regular text-[13px] bg-transparent text-white hover:border-green-300 focus:border-primary-green"
              rows="4"
              onChange={(e) => setMessage(e.target.value)}
            >
              {message}
            </textarea>

            <div className="mt-1">
              <button
                className="main_button text-white p-2 w-full"
                style={{ borderRadius: 5 }}
              >
                Envoyer Votre Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
