import axios from "axios";
import appConfig from "../constants/configurations";

const axiosInstance = axios.create({
    baseURL: appConfig.API_URL,
    headers:{
        'Content-Type': 'application/json'
    }
})

export default axiosInstance