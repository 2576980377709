import React from "react";
import IMAGES from "../../../constants/images";
import Title from "../../../components/Title";
import { BsCheck2All } from "react-icons/bs";
import agricole from "../../../images/img/agricole.jpeg";
import agricole2 from "../../../images/img/agricole2.jpeg";
import analyse from "../../../images/img/analyse.jpeg";
import valorisation from "../../../images/img/valorisation.jpeg";
import population from "../../../images/img/consult.jpg";
import entrepreneur from "../../../images/img/entrepreneur.jpg";
import formation from "../../../images/img/formation.png";
import enquete from "../../../images/img/enquete.png";
import analysis from "../../../images/img/analysis.jpg";
import online from "../../../images/img/online.jpg";
import datac from "../../../images/img/datac.webp";

const missions = [
  {
    id: 0,
    active: true,
    image: entrepreneur,
    title:
      "l'accompagnement des futurs entrepreneurs dans les études de marché",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Un Business plan disponible",
      },
      {
        id: 1,
        label: "Une Note conceptuelle pour études de marché",
      },
      {
        id: 2,
        label: "Un rapport d’étude de marché",
      },
      {
        id: 3,
        label: "etc",
      },
    ],
  },
  {
    id: 1,
    active: true,
    image: analyse,
    title:
      "Collecte et analyse des données agropastorales et socio-économiques et démographiques",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Un plan d’analyse",
      },
      {
        id: 1,
        label: "Un rapport d’analyse",
      },
      {
        id: 2,
        label: "Une base de données disponible",
      },
      {
        id: 3,
        label: "etc",
      },
    ],
  },
  {
    id: 2,
    active: true,
    image: agricole,
    title: "Conception et réalisation d’enquêtes agricoles",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Des documents de projet de l’étude",
      },
      {
        id: 1,
        label: "Un Manuel de l’agent enquêteur",
      },
      {
        id: 2,
        label: "Un rapport d’enquêtes",
      },
      {
        id: 3,
        label: "Un plan d’analyse",
      },
      {
        id: 4,
        label: "Un rapport d’analyse",
      },
      {
        id: 5,
        label: "Une base de données disponible",
      },
      {
        id: 6,
        label: "Reprographie",
      },
    ],
  },
  {
    id: 3,
    active: true,
    image: enquete,
    title:
      "Conception et réalisation d’enquêtes socio-économique pour des études de planification urbaine, de marchés etc",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Des documents de projet de l’étude",
      },
      {
        id: 1,
        label: "Un Manuel de l’agent enquêteur",
      },
      {
        id: 2,
        label: "Un rapport d’enquêtes",
      },
      {
        id: 3,
        label: "Un plan d’analyse",
      },
      {
        id: 4,
        label: "Un rapport d’analyse",
      },
      {
        id: 5,
        label: "reprographie",
      },
    ],
  },
  {
    id: 4,
    active: true,
    image: population,
    title: "Conception et réalisation d’autres enquêtes",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Des documents de projet de l’étude",
      },
      {
        id: 1,
        label: "Un Manuel de l’agent enquêteur",
      },
      {
        id: 2,
        label: "Un rapport d’enquêtes",
      },
      {
        id: 3,
        label: "Un plan d’analyse",
      },
      {
        id: 4,
        label: "Un rapport d’analyse",
      },
      {
        id: 5,
        label: "reprographie",
      },
    ],
  },
  {
    id: 5,
    active: true,
    image: IMAGES.PROJET,
    title: "Montage des projets issus des problèmes de population",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Des documents de projet",
      },
      {
        id: 1,
        label: "Une stratégie de mise en œuvre",
      },
      {
        id: 2,
        label: "Une grilles de suivi évaluation de la mise en œuvre",
      },
    ],
  },
  {
    id: 6,
    active: true,
    image: valorisation,
    title:
      "Valorisation des données existantes (Données de CountrySTAT, ONU-Habitat, INS, UNESCO, HCR, etc)",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Des articles publiés trimestriellement",
      },
      {
        id: 1,
        label: "Des mises à jour des indicateurs de performance",
      },
      {
        id: 2,
        label: "etc",
      },
    ],
  },
  {
    id: 7,
    active: true,
    image: formation,
    title:
      "Formation des acteurs sociaux en analyse des données et utilisation des outils statistiques",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Une note conceptuelle de formation",
      },
      {
        id: 1,
        label: "Des programmes de formation",
      },
      {
        id: 2,
        label: "Une attestation de fin de formation",
      },
      {
        id: 3,
        label: "etc",
      },
    ],
  },
  {
    id: 8,
    active: true,
    image: agricole2,
    title:
      "Appui technique auprès des Cabinets agropastorales, d’urbanistes et des ONGs œuvrant dans le domaine de l’agropastorale et de l’Humanitaire",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Des articles publiés trimestriellement",
      },
      {
        id: 1,
        label: "Des mises à jour des indicateurs de performance",
      },
      {
        id: 2,
        label: "etc",
      },
    ],
  },
  {
    id: 9,
    active: true,
    image: analysis,
    title: "Gestion et analyse des données ",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Collecte des données suivant la démarche d'assurance qualité",
      },
      {
        id: 1,
        label: "Traitement des données tant statistiques que géospatiales",
      },
      {
        id: 2,
        label: "Analyse des données",
      },
      {
        id: 3,
        label: "Stockage des données",
      },
      {
        id: 4,
        label: "Partage des données",
      },
    ],
  },
  {
    id: 10,
    active: true,
    image: online,
    title: "Offre de service data online",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Répondre en temps réel aux besoins en information des clients",
      },
      {
        id: 1,
        label: "Répondre via notre plateforme au besoin en données",
      },
      {
        id: 2,
        label:
          "Répondre au besoin en indicateurs des clients et utilisateurs de notre plateforme",
      },
      {
        id: 3,
        label:
          "Visualisation via notre plateforme des indicateurs de performance souhaités par nos partenaires et clients",
      },
    ],
  },
  {
    id: 11,
    active: true,
    image: datac,
    title: "Data center",
    description: "Pour cette mission, nous vous procurons",
    options: [
      {
        id: 0,
        label: "Possibilité de stocker vos données de masse",
      },
      {
        id: 1,
        label: "Possibilité de traiter vos données de masse",
      },
      {
        id: 2,
        label: "etc",
      },
    ],
  },
];

function Missions() {
  return (
    <div className="container_fluid py-24">
      <Title title="Nous Intervenons sur vos différentes missions" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {missions &&
          missions.map((item) => {
            // ${item.id % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"}
            return (
              <div
                key={item.id}
                className={`flex flex-col overflow-hidden rounded-md bg-white shadow
                `}
              >
                <div className="group relative w-full flex items-center justify-center h-[330px] overflow-hidden">
                  <img
                    src={item.image}
                    alt="Missions"
                    className="h-full w-full cursor-pointer object-cover animation duration-300 group-hover:scale-110 hover:opacity-70"
                  />

                  <div className="absolute h-full w-full bg-primary-green/90 flex flex-col gap-4 justify-center -bottom-10 group-hover:bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300 pl-12">
                    {item.options &&
                      item.options.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="flex items-center gap-1 text-white"
                          >
                            <div>
                              <BsCheck2All />
                            </div>
                            <span>{item.label}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="w-full flex flex-col bg-white">
                  <div className="flex flex-col gap-4 px-4 py-8 ">
                    <span className="font-bold text-[18px] uppercase">
                      {item.title}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Missions;
