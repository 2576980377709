import React from "react";
import Landingnavbar from "../pages/landing/navbar/Landingnavbar";
import Landingfooter from "../pages/landing/footer/Landingfooter";
import styles from "./layouts.module.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import IMAGES from "../constants/images";

function Pagelayout({ children, title, description }) {
  return (
    <div>
      <Landingnavbar />
      <div className={`${styles.pagelayout} flex justify-center items-center`}>
        <div className="container_fluid flex flex-col md:flex-row justify-center items-center">
          <div className="flex flex-col justify-center items-center text-center mt-9 pb-9">
            <h1 className="font-semibold text-2xl md:text-5xl md:mb-6 text-white text-center ">
              {title ? title : ""}
            </h1>
            <span className="text-[15px] text-gray-100 font-medium">
              {description ? description : "GESTA at your service"}
            </span>
            <div className="flex flex-row items-center mt-4 justify-center">
              <div className="flex flex-row gap-2 mb-3 items-center">
                <div
                  className="w-24 h-1 rounded-md bg-white"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                ></div>
                <div
                  className="w-3 h-3 rounded-full bg-white"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                ></div>
                <div
                  className="w-24 h-1 rounded-md bg-white"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{children}</div>
      <Landingfooter />
      {/* WhatsApp Bot */}
      <FloatingWhatsApp
        accountName="SCSM"
        phoneNumber="+237663433487"
        notification={true}
        className="mr-8"
        notificationSound={true}
        chatMessage="Salut, comment pouvons nous vous aider ?"
        placeholder="Entrer votre message..."
        statusMessage="Nous somme à votre service"
        avatar={IMAGES.LOGO}
      />
    </div>
  );
}

export default Pagelayout;
