import React from "react";
import Landingfooter from "../pages/landing/footer/Landingfooter";
import Landingnavbar from "../pages/landing/navbar/Landingnavbar";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import IMAGES from "../constants/images";

function Landinglayout({ children, title, status }) {
  return (
    <div title={title ? title : ""}>
      <Landingnavbar status={status} />

      {children}

      <FloatingWhatsApp
        accountName="SCSM"
        phoneNumber="+237663433487"
        notification={true}
        className="mr-8"
        notificationSound={true}
        chatMessage="Salut, comment pouvons nous vous aider ?"
        placeholder="Entrer votre message..."
        statusMessage="Nous somme à votre service"
        avatar={IMAGES.LOGO}
      />
      <Landingfooter />
    </div>
  );
}

export default Landinglayout;
