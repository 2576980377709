import React from "react";
import Pagelayout from "../../layouts/Pagelayout";
import Carriere from "./carriere/Carriere";

function Landingcarriere() {
  return (
    <Pagelayout
      title="SCSM, un lieu conviviale pour votre carrière"
      description="Nous avons un champs d'impacte diversifié"
    >
      <Carriere />
    </Pagelayout>
  );
}

export default Landingcarriere;
