import React from "react";
import Title from "../../../components/Title";
import styles from "./agility.module.css";
import { IMAGES } from "../../../constants/images";
import { BsCheck2All } from "react-icons/bs";

const data = [
  {
    label: "Pour un travail de qualité et la recherche de la satisfaction.",
    delay: "50",
  },
  {
    label: "Pour une gestion axée sur les résultats.",
    delay: "100",
  },
  {
    label: "Pour un travail efficient",
    delay: "150",
  },
  {
    label: "Nous vous offrons un suivie et amélioration continue.",
    delay: "200",
  },
];

function WhyUs() {
  return (
    <div className={`${styles.agility} flex justify-center items-center`}>
      <div className="container_fluid section">
        <Title title="Pourquoi nous choisir" />
        <div className="flex flex-col md:flex-row gap-x-8 justify-center items-center">
          <div className="md:w-1/3">
            <img
              src={IMAGES.ABOUTUS}
              data-aos="zoom-in"
              data-aos-duration="1000"
              alt=""
            />
          </div>
          <div className="md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8 md:gap-y-8 p-8">
            {/* Users Card */}
            {data.length &&
              data.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="bg-white rounded-2xl px-4 py-8 top-shadow -rotate-3 cursor-pointer animation duration-300 hover:scale-105"
                  >
                    <div>
                      <BsCheck2All className="text-primary-green" />
                    </div>
                    <span>{item.label}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
