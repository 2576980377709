import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Title from "../../../components/Title";
import appConfig from "../../../constants/configurations";
import IMAGES from "../../../constants/images";
import axiosInstance from "../../../utils/axiosInstance";

function Carriere() {
  const { isLoading, data, refetch, isSuccess } = useQuery(
    "carriereData",
    async () => {
      const { data } = await axiosInstance.get(`${appConfig.API_URL}/carriere/list`);
      return data.data;
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container_fluid py-20">
      {isLoading ? (
        <div className="flex w-full justify-center items-center py-12">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : isSuccess && data.length > 0 ? (
        <div>
          <Title title="Nos Offres D'emplois récentes" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data &&
              data.slice(0, 6).map((item) => {
                //   var date1 = new Date(item.deadline);
                //   var date2 = new Date.now();

                //   var Difference_In_Time = date1.getTime() - date2.getTime();

                //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

                return (
                  <Link
                    key={item.id}
                    to={`/carriere/${item.id}`}
                    className="border-[1px] border-gray-200 pt-1 bg-primary-green animation duration-300 hover:-translate-y-2"
                  >
                    <div className="h-full w-full flex flex-col gap-3 bg-white px-3 py-6">
                      <div className="flex gap-2 items-center uppercase text-sm font-semibold">
                        <span>{item.country}</span>
                        <span className="h-4 w-[1.4px] bg-slate-300"></span>
                        <span>{item.town}</span>
                      </div>
                      <h2 className="text-[22px] font-bold">{item.title}</h2>
                      <div className="flex gap-2 items-center">
                        <span className="font-medium">Deadline: </span>
                        <span className="text-base">{item.deadline}</span>
                      </div>
                      <span className="text-sm mt-16">
                        {/* {Difference_In_Days} */}
                        Depuis 11 jours
                      </span>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col">
          <div className="w-52">
            <img src={IMAGES.NOJOB} alt="No Job" />
          </div>
          <span className="text-xl md:text-2xl text-center">
            Occune Offres D'emploie pour le moment
          </span>
        </div>
      )}
    </div>
  );
}

export default Carriere;
