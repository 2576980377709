import React from "react";

function FormInput({ placeholder, type, value, name, onChange, required }) {
  return (
    <input
      type={type ? type : "text"}
      value={value}
      name={name}
      className="animation ease-in-out duration-300 outline-none border-[1px] bg-transparent rounded-md p-3 font-regular text-[13px] w-full text-white hover:border-green-300 focus:border-primary-green"
      placeholder={placeholder ? placeholder : ""}
      onChange={onChange}
      required={required ? required : false}
    />
  );
}

export default FormInput;
