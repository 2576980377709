import React from "react";
import { BiChevronRight } from "react-icons/bi";
import {
  BsChat,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsPhone,
  BsTwitter,
  BsWhatsapp,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { IMAGES } from "./../../../constants/images";
import styles from "./footer.module.css";

const links = [
  {
    label: "Accueil",
    path: "/",
  },
  {
    label: "A Propos",
    path: "/",
  },
  {
    label: "Services",
    path: "/services",
  },
  {
    label: "Missions",
    path: "/missions",
  },
  {
    label: "Contact",
    path: "/contact",
  },
];

const services = [
  {
    label: "Sondages",
    path: "/",
  },
  {
    label: "Consulting",
    path: "/",
  },
  {
    label: "Etude de marché.",
    path: "/",
  },
  {
    label: "Collecte et Analyse",
    path: "/",
  },
];

function Landingfooter() {
  const Callto = ({ phone }) => {
    return (
      <a href={`tel:${phone}`} className="cursor-pointer hover:text-slate-300">
        {phone}
      </a>
    );
  };

  return (
    <>
      <div
        className={`${styles.footer} justify-center items-center shadow py-16 bg-primary-dark`}
      >
        <div className="container_fluid flex flex-col  gap-4">
          <div className="flex flex-col gap-4 mt-6 md:mt-9 md:gap-1 md:flex-row">
            <div className="md:w-2/5 flex flex-col gap-4">
              <div className="flex flex-col gap-4 md:gap-4">
                {/* Logo */}
                <div className="brand flex flex-row items-center md:justify-start justify-center gap-2">
                  <div className="drop-shadow-lg w-[110px] bg-white flex items-center p-2 rounded-md">
                    <img
                      src={IMAGES.LOGO}
                      alt="Brand"
                      className="w-full object-scale-down"
                    />
                  </div>
                </div>

                {/* Description */}
                <span className="pr-9 text-[15px] text-white text-center md:text-start">
                  SCSM est un cabinet de consultation, de sondage, de collecte
                  et d’analyse des données agro-pastorales et de population.
                </span>
              </div>
              {/* Social media */}
              <div className="flex flex-row gap-4">
                <Link>
                  <BsFacebook
                    size={20}
                    className="text-blue-500 hover:text-blue-600"
                  />
                </Link>
                <Link
                  onClick={() =>
                    window.open(
                      "https://wa.me/+237620307439?text='Hello Gesta,I need your services. Can you help me ? "
                    )
                  }
                  target="_blank"
                >
                  <BsWhatsapp
                    size={20}
                    className="text-green-500 hover:text-green-600"
                  />
                </Link>
                <Link>
                  <BsInstagram
                    size={20}
                    className="text-red-400 hover:text-red-500"
                  />
                </Link>

                <Link>
                  <BsTwitter
                    size={20}
                    className="text-blue-400 hover:text-blue-500"
                  />
                </Link>
                <Link
                  to="https://www.linkedin.com/in/sergeo-selagsa-moffo-138439198/"
                  target="_blank"
                >
                  <BsLinkedin
                    size={20}
                    className="text-blue-500 hover:text-blue-600"
                  />
                </Link>
              </div>
            </div>

            <div className="md:w-3/5 gap-9 grid grid-cols-2 md:grid-cols-3">
              {/* Links */}
              <div className="flex flex-col gap-3">
                <span className="font-semibold uppercase text-white">
                  Lien rapide
                </span>
                <div className="flex flex-col">
                  {links &&
                    links.map((item) => {
                      return (
                        <Link
                          key={item.label}
                          to={item.path ? item.path : "/"}
                          className="flex flex-row gap-1 animation ease-in-out duration-300 hover:mx-1 items-center hover:text-slate-300 mb-4"
                        >
                          <BiChevronRight
                            color="text-white"
                            className="text-white"
                          />
                          <span className="font-regular text-[15px] text-white">
                            {item.label}
                          </span>
                        </Link>
                      );
                    })}
                </div>
              </div>

              {/* Services */}
              <div className="flex flex-col gap-3">
                <span className="font-semibold uppercase text-white ">
                  Nos Services
                </span>
                <div className="flex flex-col ">
                  {services &&
                    services.map((item) => {
                      return (
                        <Link
                          key={item.label}
                          to={item.path ? item.path : "/"}
                          className="flex flex-row gap-1 animation ease-in-out duration-300 hover:mx-1 items-center text-white hover:text-slate-300 mb-4"
                        >
                          <BiChevronRight color="text-primary-light" />
                          <span className="font-regular text-[15px]">
                            {item.label}
                          </span>
                        </Link>
                      );
                    })}
                </div>
              </div>

              {/* Contact Us */}
              <div className="flex flex-col gap-3">
                <span className="font-semibold uppercase text-white">
                  Nous Contactez
                </span>
                <div className="flex flex-col text-white">
                  <Link className="flex flex-row gap-2 items-center hover:text-slate-300 mb-4">
                    <span className="drop-shadow-md rounded-full bg-primary-green p-2">
                      <BsChat className="text-white" />
                    </span>
                    <span className="font-regular text-[16px]">
                      scsm@scsmaubmar.org
                    </span>
                  </Link>

                  <div className="flex flex-row gap-2 items-center mb-4">
                    <span className="drop-shadow-md bg-primary-green rounded-full p-2">
                      <BsPhone className="text-white" />
                    </span>
                    <div className="font-regular flex flex-col gap-1 text-[16px]">
                      <Callto phone="+(237) 620 307 439" />
                      <Callto phone="+(237) 674 651 856" />
                      <Callto phone="+(237) 663 433 487" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#212020] py-6 container_fluid">
        <div className="flex flex-col items-center gap-3">
          <div className="text-slate-200 text-center">
            Copyright © 2023 All rights reserved{" "}
            <Link
              to="https://gesta.vercel.app"
              target="blank"
              className="hover:text-slate-300"
            >
              | Made By <span className="text-blue-500">Gesta</span>
            </Link>
          </div>
          {/* <hr className="w-full" /> */}
        </div>
      </div>
    </>
  );
}

export default Landingfooter;
