export const IMAGES = {
  FEATURE_SECTION: require("../images/hero-img.png"),
  CODE_GIRL: require("../images/coder_girl.jpeg"),
  SERVICE_SECTION: require("../images/services.png"),
  LOGO: require("../images/logo.png"),
  LOGO_2: require("../images/G.png"),
  ABOUTUS: require("../images/about.png"),
  NOJOB: require("../images/nojob.jpg"),

  // Services
  ACCOMP: require("../images/img/accompagnement.png"),
  COLLECT: require("../images/img/analyse.png"),
  REALISATION: require("../images/img/realisation.png"),
  CONCEPTION: require("../images/img/conception.png"),
  PROJET: require("../images/img/project.png"),
  FORMATION: require("..//images/img/accompagnement.png"),

  // Members
  SERGEO: require("../images/sergeo.png"),

  // Clients
  PAT1: require("../images/clients/pat1.png"),
  PAT2: require("../images/G.png"),
  PAT3: require("../images/clients/pat2.jpg"),
  PAT4: require("../images/clients/pat3.png"),
  PAT5: require("../images/clients/pat4.jpg"),
  PAT6: require("../images/clients/pat5.jpg"),

  // ACCOMPLISHMENTS
  ACCOMPLISH1: require("../images/accomp1.png"),
  ACCOMPLISH2: require("../images/accomp2.png"),
  ACCOMPLISH3: require("../images/accomp3.png"),
  ACCOMPLISH4: require("../images/accomp4.png"),
  ACCOMPLISH5: require("../images/accomp5.png"),

  // PROJECTS
  PROJECT1: require("../images/project1.png"),
};

export default IMAGES;
