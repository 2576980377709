import React from "react";
import Pagelayout from "../../layouts/Pagelayout";
import Actualite from "./actualite/Actualite";

function Landingactualite() {
  return (
    <Pagelayout
      title="SCSM, un lieu convivaile pour votre carrière"
      description="Nous avons un champs d'impacte diversifié"
    >
      <div className="mt-16 md:mt-24">
        <Actualite />
      </div>
    </Pagelayout>
  );
}

export default Landingactualite;
