import React from "react";
import styles from "./clients.module.css";
import { IMAGES } from "./../../../constants/images";
import Title from "../../../components/Title";

const clients = [
  {
    name: "UNHCR",
    image: IMAGES.PAT1,
  },
  {
    name: "Gesta",
    image: IMAGES.PAT2,
  },
  {
    name: "ONU",
    image: IMAGES.PAT3,
  },
  {
    name: "FAO",
    image: IMAGES.PAT4,
  },
  {
    name: "World Food",
    image: IMAGES.PAT5,
  },
  {
    name: "UNESCO",
    image: IMAGES.PAT6,
  },
];

function Clients() {
  return (
    <div className={`${styles.clients} flex justify-center items-center`}>
      <div className="container_fluid py-24">
        <Title title="Ils ont décidé de nous faire confiance" />
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 gap-y-8 justify-items-center">
          {clients.map((item) => {
            return (
              <img
                src={item.image}
                key={item.name}
                width=""
                className="mx-9 w-[60px] md:w-[110px] object-scale-down opacity-50"
                alt=""
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Clients;
