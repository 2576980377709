import React from "react";
import StatisticsCard from "../../../components/Cards/StatisticsCard";
import styles from "./statistics.module.css";
import { BiSupport } from "react-icons/bi";
import { BsPeople, BsJournalBookmark, BsEmojiSmile } from "react-icons/bs";

function Statistics() {
  return (
    <div
      className={`${styles.statistics} flex justify-center items-center  md:-translate-y-6`}
    >
      <div className="container_fluid section">
        <div className="" data-aos="fade-up" data-aos-duration="1000">
          <div className="flex flex-col md:flex-row justify-between gap-4 rounded-lg">
            <StatisticsCard
              label="Clients Satisfait"
              icon={<BsEmojiSmile size={50} color="#4154f1" />}
              count="22"
            />
            <StatisticsCard
              label="Projets"
              icon={<BsJournalBookmark size={50} color="#d96730" />}
              count="35"
            />
            <StatisticsCard
              label="Service Client"
              icon={<BiSupport size={50} color="#15be56" />}
              count="24h/7"
            />
            <StatisticsCard
              label="Equipes"
              icon={<BsPeople size={50} color="#255d83" />}
              count="6"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
