import React from "react";

function ContactCard({ icon, label, children }) {
  return (
    <div className="drop-shadow-sm bg-[#fafbff] p-4 flex flex-col gap-1 pl-6 rounded-sm">
      {icon}
      <span className="text-primary-light font-semibold text-[20px]">
        {label}
      </span>
      <div className="text-[14px]">{children}</div>
    </div>
  );
}

export default ContactCard;
